import "share-api-polyfill";
import loadScript from "load-script";
import debounce from "debounce";
import type Confetti from "canvas-confetti";
import type { StripeConstructor } from "@stripe/stripe-js";

import { detectSystemCountry, Language, Translations } from "./language";

import { Elm } from "../elm/Main";
import {
  reportError,
  debug,
  shareLink,
  canShare,
  replaceAndReloadUrl,
  UnsupportedValueError,
} from "./helpers";
import SUPPORTED_LANGUAGES from "../data/supportedLanguages.json";
import { initLanguageSelector } from "./language";
import * as Me from "./me";
import type { Currency } from "./me";

// only provide <range-slider /> when `customElements` are supported
if ("customElements" in window) {
  require("range-slider-element");
}

declare global {
  interface Window {
    Snowflakes?: (config: unknown) => void;
    confetti?: Confetti.CreateTypes;
    Stripe?: StripeConstructor;
    dataLayer?: unknown[];
    THANKU_APP?: { init: (config: ThankuInitConfig) => void };
  }
}

/**
 * Extract the App path from the given URL
 *
 * @param url An URL string
 * @returns The App path or `null`
 */
function getAppPath(url: string): string | null {
  const appPathRegex = new RegExp(
    `/(${SUPPORTED_LANGUAGES.join("|")})/app(/.*)$`
  );
  return (url.match(appPathRegex) || [])[2] || null;
}

/**
 * Tries to detect the system currency with fallback to "EUR"
 */
function detectSystemCurrency(): Currency {
  const country = detectSystemCountry();
  switch (country) {
    case "US":
      return "USD";
    case "GB":
      return "GBP";
    case "CH":
      return "CHF";
    default:
      return "EUR";
  }
}

function runScript(url: string): Promise<void> {
  return new Promise((resolve, reject) => {
    loadScript(url, (err) => {
      if (err) {
        reportError(Error(`Couldn't load ${url}`));
        reject(err);
      } else {
        resolve();
      }
    });
  });
}

function updateHeadline(headline: string) {
  const elem = document.getElementById("headline");
  if (elem) {
    elem.textContent = headline;
  } else {
    reportError(Error("Element #headline not found"));
  }
}

function replaceUrl(url: string) {
  history.replaceState(null, "", url);
  initLanguageSelector(); // update language selector URLs
}

const replaceUrlDebounced = debounce(replaceUrl, 300);

function scrollIntoViewDeferred(id: string) {
  return requestAnimationFrame(() => {
    const elem = document.getElementById(id);
    if (elem) {
      elem.scrollIntoView({ behavior: "smooth" });
    } else {
      reportError(Error(`Element #${id} not found for scrollIntoView`));
    }
  });
}

function letItSnow() {
  if (document.getElementById("snowflakes")) return;
  const script = document.createElement("script");
  script.src =
    "https://unpkg.com/magic-snowflakes@4.1.5/dist/snowflakes.min.js";
  script.id = "snowflakes";
  script.onload = () => {
    window["Snowflakes"]?.({ color: "#5FC2C5" });
  };
  document.body.appendChild(script);
}

function playFullscreenLottie(name: string, version: string) {
  if (document.getElementById("lottie")) return;
  // load lottie player component
  const script = document.createElement("script");
  script.type = "module";
  script.src =
    "https://unpkg.com/@dotlottie/player-component@1.0.0/dist/dotlottie-player.js";
  script.id = "lottie";
  document.head.appendChild(script);
  // start lottie player
  const player = document.createElement("dotlottie-player");
  player.toggleAttribute("autoplay", true);
  player.setAttribute("src", `/${name}.${version}.lottie`);
  player.setAttribute("class", "lottie-fullscreen");
  player.addEventListener("complete", () => {
    player.remove();
  });
  player.addEventListener("click", () => {
    player.remove();
  });
  document.body.appendChild(player);
}

function confetti() {
  if (document.getElementById("confetti")) return;
  const script = document.createElement("script");
  script.src =
    "https://cdn.jsdelivr.net/npm/canvas-confetti@1.4.0/dist/confetti.browser.min.js";
  script.id = "confetti";
  script.onload = () => {
    const confetti = window["confetti"];
    const count = 200;
    const defaults: Confetti.Options = {
      origin: { y: 0.7 },
    };
    function fire(particleRatio: number, opts: Confetti.Options) {
      confetti?.({
        ...defaults,
        ...opts,
        particleCount: Math.floor(count * particleRatio),
      });
    }
    fire(0.25, {
      spread: 26,
      startVelocity: 55,
    });
    fire(0.2, {
      spread: 60,
    });
    fire(0.35, {
      spread: 100,
      decay: 0.91,
      scalar: 0.8,
    });
    fire(0.1, {
      spread: 120,
      startVelocity: 25,
      decay: 0.92,
      scalar: 1.2,
    });
    fire(0.1, {
      spread: 120,
      startVelocity: 45,
    });
  };
  document.body.appendChild(script);
}

function redirectToStripeCheckout(opts: {
  publicKey: string;
  sessionId: string;
}): Promise<void> {
  return new Promise((resolve, reject) => {
    const { sessionId, publicKey } = opts;
    if (typeof window["Stripe"] !== "function") {
      const error = Error("Stripe function n/a");
      reportError(error, { sessionId });
      reject(error);
      return;
    }
    const stripe = window["Stripe"](publicKey);
    stripe.redirectToCheckout({ sessionId }).then((result) => {
      if (result.error) {
        reportError(Error("A Stripe error occured"), {
          sessionId,
          stripeError: result.error,
        });
        reject(result.error);
      } else {
        resolve();
      }
    });
  });
}

function trackEvent(opts: { name: string; data: Record<string, unknown> }) {
  const { name, data } = opts;
  debug("app")("trackEvent", name, data);
  window["dataLayer"] = window["dataLayer"] || [];
  window["dataLayer"].push({ ...data, event: name });
}

function coBrand(opts: { slug: string }) {
  const { slug } = opts;

  // skip loading if styles are already available
  const oldStyles = document.getElementById("co-brand-styles");
  if (oldStyles?.dataset.slug === slug) return;

  // remove old co-brand styles
  oldStyles?.remove();
  document.body.classList.forEach((className) => {
    if (className.startsWith("co-brand--")) {
      document.body.classList.remove(className);
    }
  });

  // add new co-brand styles
  const coBrandStyles = document.createElement("link");
  coBrandStyles.rel = "stylesheet";
  coBrandStyles.id = "co-brand-styles";
  coBrandStyles.href = `/co-brand/${slug}/styles.css?v=2${
    /* add cache busting for development */
    process.env.NODE_ENV === "development" ? `&cb=${Date.now()}` : ""
  }`;
  coBrandStyles.dataset.slug = slug;
  document.head.appendChild(coBrandStyles);
  document.body.classList.add(`co-brand--${slug}`);
}

type ThankuInitConfig = {
  container: HTMLElement;
  translations: Translations;
  language: Language;
  stripeScriptUrl: string;
  stripePublicKey: string;
};

/**
 * Initialize the App
 *
 * @param config.container The container where the App will be rendered
 * @param config.translations The translation data (JSON object)
 * @param config.language The current language
 * @param config.stripeScriptUrl The script URL for Stripe
 * @param config.stripePublicKey The public key for Stripe
 */
function init({
  container,
  translations,
  language,
  stripeScriptUrl,
  stripePublicKey,
}: ThankuInitConfig): void {
  const appPath = getAppPath(window.location.href) || "/";
  const rememberedUser = Me.retrieveUser();
  const rememberedEmail = Me.retrieveEmail();
  const fetchCache = Me.retrieveFetchCache();
  const currency = Me.retrieveCurrency() || detectSystemCurrency();
  const app = Elm.Main.init({
    node: container,
    flags: {
      appPath,
      translations,
      language,
      currency,
      canShare,
      rememberedUser,
      rememberedEmail,
      fetchCache,
    },
  });

  // Elm Messages for JS
  app.ports.elmToJs.subscribe((portData) => {
    switch (portData.action) {
      // Update document title
      case "updateTitle":
        document.title = portData.payload;
        return;

      // Update headline of page header
      case "updateHeadline":
        updateHeadline(portData.payload);
        return;

      // Write given text to clipboard
      case "copyToClipboard":
        navigator.clipboard.writeText(portData.payload);
        return;

      // Scroll element with given id into view
      case "scrollIntoView":
        scrollIntoViewDeferred(portData.payload.id);
        return;

      // Share link
      case "shareLink":
        shareLink({
          url: portData.payload,
          language,
        });
        return;

      // Forget / delete stored data of current user
      case "forgetMe":
        Me.forget();
        return;

      // Replace Url (via History API)
      case "replaceUrl":
        replaceUrlDebounced(portData.payload);
        return;

      // Replace and reload Url
      case "replaceAndReloadUrl":
        replaceAndReloadUrl(portData.payload);
        return;

      // Let it snow, let it snow, let it snow
      case "letItSnow":
        letItSnow();
        return;

      // Kölle alaaf :)
      case "confetti":
        confetti();
        return;

      // Track analytics events
      case "trackEvent":
        trackEvent(portData.payload);
        return;

      // load co-brand styles
      case "coBrand":
        coBrand(portData.payload);
        return;

      // Remember / store the user's currency selection
      case "rememberCurrency":
        Me.storeCurrency(portData.payload);
        return;

      // Remember / store the user
      case "rememberUser":
        Me.storeUser(portData.payload);
        return;

      // Remember / store the user's email
      case "rememberEmail":
        Me.storeEmail(portData.payload);
        return;

      // Update the Fetch Cache
      case "updateFetchCache":
        Me.storeFetchCache(portData.payload);
        return;

      // Load the Stripe JS file and notify on success/error
      case "loadStripeScript":
        runScript(stripeScriptUrl).then(
          () => {
            app.ports.onLoadStripeScript.send(true);
          },
          (/* err */) => {
            app.ports.onLoadStripeScript.send(false);
          }
        );
        return;

      // Redirect to Stripe Checkout and notify on error
      case "redirectToStripeCheckout":
        redirectToStripeCheckout({
          publicKey: stripePublicKey,
          sessionId: portData.payload.sessionId,
        }).catch((err) => {
          app.ports.onRedirectToStripeCheckoutError.send(err.message);
        });
        return;

      // Un/Set preview (adds/removes class "is-preview" on <body>)
      case "setPreview":
        document.body.classList.toggle("is-preview", portData.payload);
        return;

      case "selectInput": {
        const id = portData.payload;
        const input = document.getElementById(id) as HTMLInputElement;
        input.select();
        return;
      }

      case "goBack":
        history.back();
        return;

      case "playFullscreenLottie":
        const { name, version } = portData.payload;
        playFullscreenLottie(name, version);
        return;

      default:
        throw new UnsupportedValueError(portData);
    }
  });
}

window["THANKU_APP"] = { init };
