import {
  getRandomBase64Char,
  getFromLocalStorage,
  setToLocalStorage,
  removeFromLocalStorage,
} from "./helpers";
import * as Base64 from "./base64";

export type Email = string;

export type FetchCache = string[][];

export type Currency = "EUR" | "GBP" | "CHF" | "USD";

export type User = {
  id: string;
  slug: string;
  nickname: string;
  gravatarHash: string;
  email: Email;
  language: string;
};

export function retrieveCurrency(): Currency | null {
  return getFromLocalStorage("ccy") as Currency | null; // ccy = currency
}

export function retrieveUser(): User | null {
  const usr = getFromLocalStorage("usr"); // usr = user
  if (usr === null) return null;
  try {
    // de-obfuscate and parse user data [2]
    return JSON.parse(Base64.decode(usr.substr(1))) as User;
  } catch {
    return null;
  }
}

export function retrieveEmail(): Email | null {
  const uea = getFromLocalStorage("uea"); // u.ser e.mail a.ddress
  if (uea === null) return null;
  try {
    // de-obfuscate the user's email [1]
    return Base64.decode(uea.substr(1));
  } catch {
    return null;
  }
}

export function retrieveFetchCache(): FetchCache {
  const fc = getFromLocalStorage("fc"); // f.etch c.ache
  if (fc === null) return [];
  try {
    return JSON.parse(Base64.decode(fc));
  } catch {
    return [];
  }
}

export function storeCurrency(currency: Currency): void {
  setToLocalStorage("ccy", currency);
}

export function storeUser(user: User): void {
  // obfuscate user data [2]
  const value = getRandomBase64Char() + Base64.encode(JSON.stringify(user));
  setToLocalStorage("usr", value);
}

export function storeEmail(email: Email): void {
  // obfuscate the user's email [1]
  setToLocalStorage("uea", getRandomBase64Char() + Base64.encode(email));
}

export function storeFetchCache(cache: FetchCache): void {
  setToLocalStorage("fc", Base64.encode(JSON.stringify(cache)));
}

export function forget(): void {
  removeFromLocalStorage("ccy");
  removeFromLocalStorage("usr");
  removeFromLocalStorage("uea");
  removeFromLocalStorage("fc");
}
