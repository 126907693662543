import SUPPORTED_LANGUAGES from "../data/supportedLanguages.json";
import {
  debug,
  replaceAndReloadUrl,
  getFromLocalStorage,
  setToLocalStorage,
} from "./helpers";

export type Language = "en" | "de";

export type Translations = {
  [k: string]: string | Translations;
};

/**
 * Replaces the language in the given path
 * @param path A location path
 * @param lang The language to be replaced
 * @returns The path with the given language replaced
 * @throws
 */
function replaceLanguage(path: string, lang: Language): string {
  const langGroup = `(${SUPPORTED_LANGUAGES.join("|")})`;
  const langRegex = new RegExp(`^/${langGroup}`);
  const pathRegex = new RegExp(`^/${langGroup}(/.*)?$`);
  if (pathRegex.test(path)) return path.replace(langRegex, `/${lang}`);
  throw Error("path not supported");
}

/**
 * Get the current URL w/o the origin
 * @returns URL w/o origin
 */
function getUrlNoOrigin(): string {
  return location.pathname + location.search + location.hash;
}

/**
 * Checks if the given language is supported
 * @param lang The language to check
 * @returns Wether the given language is supported
 */
export function isLanguageSupported(lang: string): boolean {
  return SUPPORTED_LANGUAGES.indexOf(lang) >= 0;
}

/**
 * Detects the system language (x-browser)
 * @returns The navigator language
 */
export function detectSystemLanguage(): string {
  const language =
    (navigator.languages && navigator.languages[0]) || // Chrome / Firefox
    navigator.language; // All browsers
  return language.split("-")[0];
}

/**
 * Tries to detects the system country (x-browser)
 * @returns The navigator country if available
 */
export function detectSystemCountry(): string | undefined {
  const languages =
    navigator.languages || (navigator.language && [navigator.language]) || [];
  const country = languages.reduce<string | undefined>(
    (prev, lang) => prev || lang.split("-")[1]?.toUpperCase(),
    undefined
  );
  return country;
}

/**
 * Returns the language from the <html> tag ("en" is fallback)
 * @returns The document language
 */
export function getDocumentLanguage(): Language {
  const lang =
    document.querySelector("html")?.getAttribute("lang") || "unknown";
  return (SUPPORTED_LANGUAGES.includes(lang) ? lang : "en") as Language;
}

/**
 * Initializes the language recommendation
 * @param targetLanguage The target language used for initialization
 */
export function initLanguageRecommendation(targetLanguage: string): void {
  // lrs = l.anguage r.ecommendation s.hown
  // lng = language
  const wasAlreadyShown = Boolean(getFromLocalStorage("lrs"));
  const hasLanguageStored = Boolean(getFromLocalStorage("lng"));

  if (wasAlreadyShown || hasLanguageStored) {
    debug("language")("Language recommendation skipped");
    return; // early exit
  }

  const container = document.getElementById("language-recommendation");
  const closeBtn = document.getElementById("language-recommendation-close");
  if (!container || !closeBtn) return; // early exit

  const link = container.querySelector(`a[lang="${targetLanguage}"]`);
  if (!link) return; // early exit

  let href: string;
  try {
    href = replaceLanguage(getUrlNoOrigin(), targetLanguage as Language);
  } catch (err) {
    return; // early exit
  }
  link.setAttribute("href", href);
  link.addEventListener("click", (event) => {
    event.preventDefault();
    setToLocalStorage("lng", targetLanguage);
    replaceAndReloadUrl(href);
  });
  link.removeAttribute("hidden");

  const dismiss = (): void => {
    container.setAttribute("hidden", "");
    closeBtn.removeEventListener("click", dismiss);
  };
  closeBtn.addEventListener("click", dismiss);
  setTimeout(() => {
    container.removeAttribute("hidden");
    setToLocalStorage("lrs", "true");
  }, 1500);
}

/**
 * Initializes the language selector (part of navigation)
 * and language selection in footer
 */
export function initLanguageSelector(): void {
  const languageLinks = document.querySelectorAll(
    "#language-selector a[lang][href], #footer-language-selection a[lang][href]"
  );

  // X-Browser alternative to languageLinks.forEach
  Array.prototype.forEach.call(languageLinks, (link: Element) => {
    const lang = link.getAttribute("lang") as Language;
    let href: string;
    try {
      href = replaceLanguage(getUrlNoOrigin(), lang);
    } catch {
      return; // early exit
    }
    link.setAttribute("href", href);
    link.addEventListener("click", (event) => {
      event.preventDefault();
      setToLocalStorage("lng", lang);
      replaceAndReloadUrl(href);
    });
  });
}

export function redirectToStoredLanguage(currentLang: Language): void {
  const storedLanguage = getFromLocalStorage("lng") as Language | null;
  if (
    !!storedLanguage &&
    isLanguageSupported(storedLanguage) &&
    isLanguageSupported(currentLang) &&
    storedLanguage !== currentLang
  ) {
    let url: string;
    try {
      url = replaceLanguage(getUrlNoOrigin(), storedLanguage);
    } catch {
      return;
    }
    replaceAndReloadUrl(url);
  }
}
