import type { Language } from "./language";

type BugsnagClient = {
  notify: (error: Error, config: unknown) => void;
};

declare global {
  interface Window {
    bugsnagClient?: BugsnagClient;
  }
}

/**
 * Report error to Bugsnag if defined or just log
 *
 * @param error The error to log
 * @param meta Optional meta data to log
 */
export function reportError(
  error: Error,
  meta?: Record<string, unknown>
): void {
  if (window["bugsnagClient"] !== undefined) {
    window["bugsnagClient"].notify(error, {
      metaData: meta,
    });
  } else {
    console.error(error, meta);
  }
}

/**
 * Replaces the URL of the current page
 * (overwriting history, not keeping the referrer)
 * @param url The new URL
 */
export function replaceAndReloadUrl(url: string): void {
  history.replaceState(null, "", url);
  location.href = url;
}

/**
 * https://www.npmjs.com/package/debug for the poor ;)
 *
 * Example:
 *   debug("app")(apiResult, someStatus, someFlag)
 *
 * @param scope The debug scope, e.g. "app"
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function debug(scope: string) {
  return function (...args: unknown[]): void {
    if (getFromLocalStorage("debug") === "*") {
      console.log(`[DEBUG] ${scope} --`, ...args);
    }
  };
}

/**
 * Returns a random Base64 character (only alpha-numeric)
 */
export function getRandomBase64Char(): string {
  const chars =
    "0123456789qwertyuiopasdfghjklzxcvbnmQWERTYUIOPASDFGHJKLZXCVBNM";
  return chars[Math.round(Math.random() * (chars.length - 1))];
}

/**
 * Triggers native share link dialog (if supported)
 */
export function shareLink({
  url,
  language,
}: {
  url: string;
  language?: Language;
}): void {
  navigator
    .share(
      { url, title: "", text: "" },
      /* extra options for https://www.npmjs.com/package/share-api-polyfill */
      { skype: false, language: language || "en" }
    )
    .catch((err: Error) => {
      if (err.name === "AbortError") {
        // ignore when user aborts the share dialog
      } else {
        reportError(err);
      }
    });
}

/**
 * Does the browser support native share functionality?
 */
export const canShare = typeof navigator.share === "function";

/**
 * Custom error used for exhaustiveness checking:
 * https://2ality.com/2020/02/typescript-exhaustiveness-checks-via-exceptions.html
 */
export class UnsupportedValueError extends Error {
  constructor(value: never) {
    super("Unsupported value: " + value);
  }
}

export function getFromLocalStorage(key: string): string | null {
  try {
    return window.localStorage.getItem(key);
  } catch (err) {
    reportError(err as Error);
    return null;
  }
}

export function setToLocalStorage(key: string, value: string): void {
  try {
    window.localStorage.setItem(key, value);
  } catch (err) {
    reportError(err as Error);
  }
}

export function removeFromLocalStorage(key: string): void {
  try {
    window.localStorage.removeItem(key);
  } catch (err) {
    reportError(err as Error);
  }
}
